import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';


interface CustomError {
    data: {
        error: string;
        message: any;
        statusCode: number;
    };
    status: number;
}

const baseUrl = "https://api.roadlers.com/api/v1/";

export const onboardingApi = createApi({
    reducerPath: 'onboardingApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState)?.auth?.auth?.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}` );
            }
            return headers;
        },
    }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
    endpoints: builder => ({
        login: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'login',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
        })
    }),
});

export const {
    useLoginMutation
} = onboardingApi;