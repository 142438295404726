import React, { useEffect, useState } from 'react'
import Footer from '../../../components/footer'
import Header from '../../../components/header'
import './styles/Login.css'
import 'react-toastify/dist/ReactToastify.css';

import logo from "../../../assets/images/svg/roadlers-profile-logo.svg"
import spin from "../../../assets/images/gif/spinner.gif"
import closedEye from "../../../assets/images/svg/closed-eye.svg"
import openedEye from "../../../assets/images/svg/opened-eye.svg"

import { ToastContainer, toast } from 'react-toastify';
import { useLoginMutation } from '../../../services/onboardingApi';
import { useNavigate } from 'react-router-dom';
import { setAuth } from '../../../slices/auth';
import { useAppDispatch } from '../../../store';
// import { useDispatch } from 'react-redux';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [btnLoad, setBtnLoad] = useState(false)
  const [eyeOpen, setEyeOpen] = useState<any>("password")
  const dispatch = useAppDispatch()

  // RTK endpoints
  const [login, { data, isLoading, isSuccess, isError, error }] = useLoginMutation();


  useEffect(() => {
    if (isLoading) {
        setBtnLoad(true)
    }
    if (isSuccess) {
        setBtnLoad(false)
        if(data?.data.user_role === "admin") {
          dispatch(setAuth(data?.data))
          navigate('/admin-blog-list')
        }else {
          dispatch(setAuth(data?.data))
          navigate('/')
        }
        
    }
    if (isError && 'status' in error!) {
        notifyError(error.data.message)
        setBtnLoad(false)
    }
}, [isLoading, isSuccess, isError, data ])

  const handleMail = (e: any) => {
    setEmail(e.target.value)
  }

  const handlePassword = (e: any) => {
    setPassword(e.target.value)
  }

  const handleLogin = (e: any) => {
    e.preventDefault()
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let formData: any = new FormData()

    formData.append('email', email)
    formData.append('password', password)

    if(email.length === 0) {
      notifyError('Please type in your mail')
    }else if (password.length === 0) {
      notifyError("Please type in your password")
    }else if(!email.match(mailformat)) {
      notifyError("Invalid Mail Format")
    }else {
      login(formData)
    }
  }

  const notifyError = (text: any) => toast.error(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  })

  return (
    <div className='login-wrapper'>
      <ToastContainer />
      <Header />
      <div className="inner-login-wrapper">
        <div className="header-text">
          <h2>Login to Blog Admin</h2>
        </div>

        <div className="login-box">
            <form className="inner-login-box">
              <div className="input-mail-box">
                <input type="email" placeholder='Your Email' value={email} onChange={(e) => handleMail(e)}/>
                <img src={logo} alt="roadlers profile logo" />
              </div>

              <div className="input-password-box">
                <input type={eyeOpen} placeholder='Password: A minimum of 6 characters with one uppercase' value={password} onChange={(e) => handlePassword(e)}/>
                <div className="img-eye">
                  {
                    eyeOpen === "password" ? 
                    <img src={openedEye} onClick={() => setEyeOpen('text')} alt="opened eye" /> : 
                    <img src={closedEye} onClick={() => setEyeOpen('password')} alt="closed eye" />
                  }
                </div>
                
              </div>

              <div className="login-btn-box">
                <button onClick={(e) => handleLogin(e)}>{btnLoad ? <img src={spin} alt='spin gif'/> : <p>Login</p>}</button>
              </div>
            </form>
        </div>
      </div>

      <Footer />

    </div>
  )
}

export default Login