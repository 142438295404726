import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';


interface CustomError {
    data: {
        error: string;
        message: any;
        statusCode: number;
    };
    status: number;
}

const baseUrl = "https://api.roadlers.com/api/v1/";

export const blogApi = createApi({
    reducerPath: 'blogApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState)?.auth?.auth?.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
    tagTypes: ['Blogs'],
    endpoints: builder => ({
        createBlogPost: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'new-post',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
            invalidatesTags: ["Blogs"]
        }),
        editBlogPost: builder.mutation({
            query: (data:{id: number, post: any}) => {
                return {
                    url: `edit-post/${data.id}`,
                    method: 'post',
                    // body, 
                    body: data.post
                };
            },
        }),
        postBlogLike: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'post-likes',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
        }),
        postComments: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'post-comment',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
        }),
        getBlogList: builder.query<any, void>({
            query: () => {
                return {
                    url: 'list-posts',
                };
            },
            providesTags: ['Blogs']
        }),
        getBlogLikes: builder.query({
            query: (id) => {
                return {
                    url: `view-likes/${id}`,
                };
            },
        }),
        getBlogPost: builder.query({
            query: (id) => {
                return {
                    url: `fetch-post/${id}`,
                };
            },
        }),
        getBlogComments: builder.query({
            query: (id) => {
                return {
                    url: `list-comments/${id}`,
                };
            },
        }),
        getBlogPostCategory: builder.query({
            query: (id) => {
                return {
                    url: `fetch-post/${id}`,
                };
            },
        }),
    }),
});

export const {
    useGetBlogListQuery,
    useEditBlogPostMutation,
    useCreateBlogPostMutation, 
    useGetBlogLikesQuery, 
    usePostBlogLikeMutation,
    usePostCommentsMutation,
    useGetBlogPostQuery,
    useGetBlogCommentsQuery, 
    useGetBlogPostCategoryQuery
} = blogApi;