import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

function AdminProtected({isSignedIn, role, children}:any) {
    if (!isSignedIn && role !== "admin"){
        return <Navigate to='login' />
    } 
    return (
        <>
            {children}
            <Outlet />
        </>
    )
  
}

export default AdminProtected