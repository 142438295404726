import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Action, combineReducers } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import { setupListeners } from '@reduxjs/toolkit/dist/query/react';
import { blogApi } from './services/blogApi';
import { onboardingApi } from './services/onboardingApi';
import authReducer from './slices/auth'
import linkReducer from './slices/link'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from "redux-persist"


const reducers = combineReducers({
    [onboardingApi.reducerPath]: onboardingApi.reducer,  
    [blogApi.reducerPath]: blogApi.reducer, 
    link:linkReducer,
    auth:authReducer,
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
        blogApi.reducerPath,
    ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],},
        }).concat(
            thunkMiddleware,
            blogApi.middleware,
            onboardingApi.middleware,
            ),
    // getDefaultMiddleware().concat(authApi.middleware) 
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
setupListeners(store.dispatch);

export default store;