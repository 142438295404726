import React, { useEffect, useState } from 'react'
import "./styles/Homepage.css"

import banner from "../../../assets/images/jpg/blogbanner.jpg"
import car from "../../../assets/images/svg/car-filter.svg"
import driver from "../../../assets/images/svg/driver-filter.svg"
import travel from "../../../assets/images/svg/travel-filter.svg"
import owner from "../../../assets/images/svg/owner-filter.svg"

import Footer from '../../../components/footer'
import Header from '../../../components/header'
import BlogCard from '../../../components/blogcard'

import { useGetBlogListQuery } from '../../../services/blogApi'
import Loader from '../../../components/loader'

const Homepage = () => {
    // @ts-ignore
    const {data, isFetching, isLoading, refetch} = useGetBlogListQuery({refetchOnFocus:true, refetchOnMountOrArgChange:true})

    // USESTATE
    const [blogListData, setBlogListData] = useState<any[]>()
    const [visibleList, setVisibleList] = useState(4)
    const [categoryType, setCategoryType] = useState("")
    const [searchBlogCategory, setSearchBlogCategory] = useState<any>(categoryType || "")
    const [findCategory, setFindCategory] = useState<any[]>()
    const [preLoad, setPreLoad] = useState(false)

    useEffect(() => {
        if(isLoading) {
            setPreLoad(false)
        }else {
            setPreLoad(true)
        }
    }, [data])

    useEffect(() => {
        refetch()
        setBlogListData(data?.data)
        const filteredData = blogListData?.filter((item) => item?.category?.toLowerCase().includes(searchBlogCategory?.toLowerCase()))?.reverse()

        setFindCategory(filteredData)
    }, [blogListData, categoryType, refetch, data])

    const handleShowMore = () => {
        setVisibleList((prevValue) => prevValue + 4)
    }

    const handlerefetch = () => {
        refetch()
    }

  return (
    isLoading? 
    <Loader /> :
    <div className='homepage'>
        <Header />

        <div className="blog-homepage-banner">
            <img src={banner} alt="blog banner" />
        </div>
        <div className="homepage-body">
            <div className="blog-category-filter">
                <div className="inner-category-filter">
                    <button className={categoryType === "cars"? "activeCategory" : ""} onClick={() => {setCategoryType("cars"); setSearchBlogCategory("cars")}}>
                        <img src={car} alt="" />
                        <p>Cars</p>
                    </button>

                    <button className={categoryType === "drive"? "activeCategory" : ""} onClick={() => {setCategoryType("drive"); setSearchBlogCategory("drive")}}>
                        <img src={driver} alt="" />
                        <p>Drivers</p>
                    </button>

                    <button className={categoryType === "travel"? "activeCategory" : ""} onClick={() => {setCategoryType("travel"); setSearchBlogCategory("travel")}}>
                        <img src={travel} alt="" />
                        <p>Travel</p>
                    </button>

                    <button className={categoryType === "owner"? "activeCategory" : ""} onClick={() => {setCategoryType("owner"); setSearchBlogCategory("owner")}}>
                        <img src={owner} alt="" />
                        <p>Ownership</p> 
                    </button>
                </div>
            </div>

            {
                !blogListData?.length ?
                <div className="go-to-upload">
                    <h2>No Blog yet </h2>
                </div>: 
                (
                    findCategory?.slice(0, visibleList).map((item, id) => {
                        return (
                        <div key={id}>
                            <BlogCard 
                                title={item.title}
                                blogContent={item.content}
                                readMinute={item.min_read}
                                numLikes={item.likes}
                                image={item.post_image}
                                bloglink={item.link}
                                buttonText="Read More"
                                pagename={'blogpost'}
                                id={item.id}
                            />
                        </div>
        
                        )
                    })
                )
            }

            <div className="view-more-container">
                {
                    !blogListData?.length || blogListData?.length <= visibleList ? "" : <button onClick={handleShowMore}>Load More</button>
                }
                
            </div>
        </div>

        <Footer />
    </div>
  )
}

export default Homepage