import React, { useState } from 'react'
import "./styles/Header.css"
import logo from "../../assets/images/svg/roadlers-logo.svg"
import toggleBar from "../../assets/images/svg/togglebar.svg"

const Header = () => {
    const [menuShow, setMenuShow] = useState(false)

    const handleMobileMenu = () => {
        setMenuShow(!menuShow)
    }

  return (
    <header>
        <nav>
            <div className="logo-wrapper">
                <a href="https://roadlers.com/">
                    <img src={logo} alt="" />
                </a>
                
            </div>

            <ul>
                <li><a href="https://roadlers.com/">Home</a></li>
                <li><a href="https://roadlers.com/#services">Services</a></li>
                <li><a href="https://roadlers.com/#partners">Partner</a></li>
                <li><a className='blog-link' href="/">Blog</a></li>
            </ul>

            <div className="buttons-wrapper">
                <a href='https://roadlers.com/login' className='login'>Login</a>
                <a href='https://roadlers.com/sign-up' className="signup">Sign-Up</a>
                
                <div className="toggle-wrapper" onClick={handleMobileMenu}>
                    <img src={toggleBar} alt="Toggle Bar" />
                </div>
            </div> 



            <div className={ menuShow ? 'mobile-menu active-mobile' : 'mobile-menu'}>
                <div className="inner-wrapper">
                    <ul className='mobile-list'>
                        <li><a href="https://roadlers.com/">Home</a></li>
                        <li><a href="https://roadlers.com/#services">Services</a></li>
                        <li><a href="https://roadlers.com/#vehicles">Vehicles</a></li>
                        <li><a href="https://roadlers.com/#drivers">Drivers</a></li>
                        <li><a href="https://roadlers.com/#promotions">Promotions</a></li>
                        <li><a href="https://roadlers.com/#partners">Partner</a></li>
                        <li><a className='blog-link' href="/">Blog</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
  )
}

export default Header