import "./styles/Footer.css"
import logo from "../../assets/images/svg/roadlers-logo.svg"
import phoneIcon from "../../assets/images/svg/phone-icon.svg"
import locationIcon from "../../assets/images/svg/location-icon.svg"
import facebook from "../../assets/images/svg/facebook.svg"
import insta from "../../assets/images/svg/insta.svg"
import twitter from "../../assets/images/svg/twitter.svg"

const Footer = () => {
  return (
    <footer>
        <div className="footer-header">
            <div className="footer-header-inner">
                <a href="https://roadlers.com/" className="logo-wrapper">
                    <img src={logo} alt="Roadler-logo" />
                </a>

                <div className="social-icon-wrapper">
                    <a href="https://www.facebook.com/people/Roadlers/100077052656143/" target='_blank' className="facebook-social">
                        <img src={facebook} alt="" />
                    </a>

                    <a href="https://www.instagram.com/roadlers/" target='_blank' className="insta-social">
                        <img src={insta} alt="" />
                    </a>

                    <a href="https://twitter.com/roadlers?t=pFGGOse56wlj5DwK0al7Sg&s=08" target='_blank' className="twitter-social">
                        <img src={twitter} alt="" />
                    </a>
                </div>
                
            </div>
        </div>

        <div className="footer-body">
            <div className="footer-body-inner">
                <div className="first-section">
                    <h2>Car Rental in Lagos Nigeria</h2>

                    <div className="contact-info-wrapper">
                        <h4>Want to visit us?</h4>

                        <div className="roadlers-address">
                            <img src={locationIcon} alt="Location" />

                            <p>2nd Floor, Suite 205 Plot 1225 Bishop Oluwole Street, Victoria Island Lagos. (Edo House)</p>
                        </div>

                        <div className="roadlers-mail-phone">
                            <img src={phoneIcon} alt="" />

                            <div className="mail-phone-inner">
                                <p>+234809 999 5947</p>
                                <p>E-mail: info@gsofacility.com</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrap-sections">
                    <div className="second-section">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.778728735539!2d3.411422214770179!3d6.422469995353229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b1fe1d1175b%3A0xd8609c7eb1bfb974!2sRoadlers%20Limited!5e0!3m2!1sen!2sng!4v1644996377492!5m2!1sen!2sng" className="roadlers-iframe"></iframe>
                    </div>

                    <div className="third-section">
                        <form action="">
                            <div className="name-input">
                                <label htmlFor="Name">Name:</label>
                                <input type="text" />
                            </div>

                            <div className="mail-input">
                                <label htmlFor="mail">Email:</label>
                                <input type="email" />
                            </div>

                            <div className="phone-input">
                                <label htmlFor="phone">Phone number:</label>
                                <input type="text" />
                            </div>

                            <div className="purpose-input">
                                <label htmlFor="puropose">Purpose:</label>
                                <textarea name="" id="" className="purpose-textarea"></textarea>
                            </div>
                        </form>

                        <div className="submit-btn-wrapper">
                            <button>
                                Book A Meeting
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div className="footer-foot">
            <div className="footer-foot-inner">
                 <p>&copy; Copyright Roadlers Limited 2022 </p>
            </div>
        </div>
    </footer>
  )
}

export default Footer