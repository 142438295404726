import React, { useEffect, useState } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import AdminProtected from '../components/AdminProtected'
import Protected from '../components/Protected'
import SmoothScroll from '../components/SmoothScroll'
import BlogEdit from '../pages/admin/BlogEdit'
import AdminBlogList from '../pages/admin/BlogListAdmin'
import BlogUpload from '../pages/admin/BlogUpload'
import Login from '../pages/auth/login'
import BlogPost from '../pages/user/BlogPost'
import Homepage from '../pages/user/Homepage'
import { RootState, useAppSelector } from '../store'

function AppWrapper() {
  const {token, user_role} = useAppSelector<any>((store: RootState) => store?.auth.auth)
  const [isSignedIn, setIsSignedIn] = useState(token?.length)


  return (
    <div>
        <Router>
            <SmoothScroll>
              <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="login" element={<Login />} />
                <Route path="blogpost/:bloglink" element={<BlogPost />} />

                {/* ADMIN PROTECTED ROUTES */}
                <Route path="/" element={<AdminProtected isSignedIn={isSignedIn} role={user_role} />}>
                  <Route path='admin-blog-list' element={<AdminBlogList />}/>
                  <Route path='upload-blog-page' element={<BlogUpload />}/>
                  <Route path='edit-blog/:bloglink' element={<BlogEdit />}/>
                </Route>

              </Routes>
            </SmoothScroll>

          </Router>
    </div>
  )
}

export default AppWrapper